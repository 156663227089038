import React from "react"
import { Link, graphql } from "gatsby"
import Tiplayout from "../components/tipLayout"
import Logo from "../components/logo"
import nordBannerImg from "../images/tip-of-the-day/nord-banner.png"
import nordRectImg from "../images/tip-of-the-day/nord-rect.png"
import classicImg1x from "../images/tip-of-the-day/classic.png"
import classicImg2x from "../images/tip-of-the-day/classic@2x.png"

export default ({ data, pageContext:{next, previous} }) => {
  const post = data.allWordpressWpTip.edges[0].node
  let nextPost = (next) ? <Link to={`/tip-of-the-day${decodeURI(next.path)}`} className="link-next" dangerouslySetInnerHTML={{ __html: next.title }}></Link> : null
  let previousPost = (previous) ? <Link to={`/tip-of-the-day${decodeURI(previous.path)}`} className="link-next" dangerouslySetInnerHTML={{ __html: previous.title }}></Link> : null
  return (
    <>
      <div className="tip-top-banner text-center py-3">
        <a class="pointer" href="https://go.nordvpn.net/aff_c?offer_id=30&amp;aff_id=30518&amp;url_id=323&amp;aff_sub=tip300_250" target="_blank" rel="noopener noreferrer">
          <img src={nordBannerImg} alt="nord" className="img-fluid"/>
        </a>
      </div>
      <Tiplayout>
        <div className="row">
          <div className="col-md-8 px-4">
            <Logo color="black" tag="p" productName="Tip of the Day" className="text-center text-md-left mb-5" fsMax={21.5}/>
              <h1 className="h3" dangerouslySetInnerHTML={{ __html: post.title }}></h1>
              <div className="text-muted d-block mb-5">{post.date}</div>
              <div className="entry-content" dangerouslySetInnerHTML={{ __html: post.content }} />
              <div className="row my-5">
                <div className="col-5 position-relative">
                {
                  previousPost && 
                    <div className="blog-post-link ">
                      {previousPost}
                      <div className="link-arrow-left link-arrow">
                        {"<"}
                      </div>
                    </div>
                }
                </div>
                <div className="offset-2 col-5 position-relative text-right">
                {
                  nextPost && 
                    <div className="blog-post-link ">
                      {nextPost}
                      <div className="link-arrow-right link-arrow">
                        {">"}
                      </div>
                    </div>
                }
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column text-center">
              <div className="tip-of-the-day-container tip-right-container h-100">
                <a href="/products/win/bittorrent-classic-free/" target="_blank" rel="noopener noreferrer"> 
                  <img className="tip-img img-fluid" srcSet={`${classicImg1x} 1x, ${classicImg2x} 2x`}  alt="classic" />
                </a>
                <a href="https://go.nordvpn.net/aff_c?offer_id=30&amp;aff_id=30518&amp;url_id=323&amp;aff_sub=tip300_250" target="_blank" rel="noopener noreferrer">
                  <img className="tip-img img-fluid" src={nordRectImg} alt="nord" />
                </a>
              </div>
          </div>
          </div>
      </Tiplayout>
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWordpressWpTip(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`